


































import gsap from 'gsap'
import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

import FlexibleAsync from '@/components/g/FlexibleAsync.vue'
import MagazineCategories from '@/components/magazine/Categories.vue'

export default defineComponent({
  name: 'MagazineSubcategoryPersonality',
  components: {
    FlexibleAsync,
    MagazineCategories,
  },
  props: {},

  setup() {
    const { content } = useGetters(['content'])

    // Appear animation for hero
    const onHeroAppear = (el: HTMLElement) => {
      const titleEl = el.querySelector('.magazine-personality__hero__title')
      const textEls = el.querySelectorAll(
        '.magazine-personality__hero__text > *'
      )
      const pictureEl = el.querySelector('.magazine-personality__hero__picture')
      const breakpoint = 960
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([el, titleEl, textEls, pictureEl], { clearProps: 'all' })
        },
      })

      tl.set(el, { clearProps: 'opacity' })

      if (window.innerWidth < breakpoint) {
        tl.from(pictureEl, { opacity: 0, duration: 1 }, 0).from(
          [titleEl, textEls],
          {
            opacity: 0,
            y: 50,
            stagger: 0.1,
            duration: 0.5,
          },
          0.1
        )
      } else {
        tl.from(pictureEl, { opacity: 0, x: -10, duration: 1.2 }, 0).from(
          [titleEl, textEls],
          {
            opacity: 0,
            x: 30,
            stagger: 0.1,
            duration: 1,
          },
          0
        )
      }
    }

    return {
      content,
      onHeroAppear,
    }
  },
})
