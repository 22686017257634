var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"aware",rawName:"v-aware"}],ref:"rootElRef",staticClass:"magazine-categories",class:{
    'is-sticky': _vm.isStickyRef,
  },on:{"scroll":_vm.onScroll,"viewport":_vm.onViewport}},[_c('div',{ref:"listElRef",staticClass:"magazine-categories__list-outer wrapper",on:{"scroll":_vm.onHorizontalScroll}},[_c('ul',{staticClass:"magazine-categories__list"},_vm._l((_vm.content),function(category){return _c('li',{key:category.value,staticClass:"magazine-categories__item-outer"},[_c('router-link',{staticClass:"magazine-categories__item",class:{ selected: category.value === _vm.selected },attrs:{"to":category.value === 'all'
              ? {
                  path: ("/" + (_vm.$route.params.lang) + "/magazine/"),
                }
              : {
                  path: ("/" + (_vm.$route.params.lang) + "/magazine/" + (category.value) + "/"),
                }}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"video__poster__play__icon",attrs:{"symbol":("magazine-" + (category.icon || 'all')),"size":"0 0 24 24"}}),_vm._v(" "+_vm._s(category.label)+" ")])],1)}),0)]),_c('button',{staticClass:"magazine-categories__button--left",class:{ hidden: _vm.hScrollRef < 20 },attrs:{"aria-label":_vm.$t('left')},on:{"click":function($event){return _vm.onArrowClick(false)}}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":"ui-arrow-small-left","size":"0 0 24 24"}})]),_c('button',{staticClass:"magazine-categories__button--right",class:{ hidden: _vm.hScrollRef > _vm.maxHScrollRef - 20 },attrs:{"aria-label":_vm.$t('right')},on:{"click":function($event){return _vm.onArrowClick(true)}}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":"ui-arrow-small-right","size":"0 0 24 24"}})])])}
var staticRenderFns = []

export { render, staticRenderFns }