













































































import type { SelectOption } from '@/inc/types'
import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

export interface MagazineCategory extends SelectOption {
  icon: string
}

export default defineComponent({
  name: 'magazine-categories',
  props: {
    content: {
      type: Array as PropType<MagazineCategory[]>,
      required: true,
    },
    selected: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  setup() {
    const rootElRef = ref<HTMLElement | null>(null)
    const listElRef = ref<HTMLElement | null>(null)
    const isStickyRef = ref(false)
    const hScrollRef = ref(0)
    const maxHScrollRef = ref(0)
    const navHeight = 60
    let distanceToTop: number

    // Apply sticky styles if sticky
    const onScroll = (x: number, y: number) => {
      if (rootElRef.value?.classList.contains('magazine-landing__categories')) {
        // Specific to magazine landing
        // Round value because of iOS bug that adds/removes .25px to the value
        const newdistanceToTop = Math.round(
          rootElRef.value!.getBoundingClientRect().top
        )

        if (distanceToTop === newdistanceToTop) {
          return
        }

        distanceToTop = newdistanceToTop + y
        isStickyRef.value = y + navHeight === distanceToTop
      } else {
        // Default (camber categories): mimic header behavior
        isStickyRef.value = y > 50
      }

      document.body.classList[isStickyRef.value ? 'add' : 'remove'](
        'sticky-dropdown'
      )
    }

    // Tracks list horizontal scroll
    // Mobile only
    const onHorizontalScroll = () => {
      if (!listElRef.value) {
        return
      }

      hScrollRef.value = listElRef.value.scrollLeft
    }

    // Scroll horizontally
    const onArrowClick = (forwards?: boolean) => {
      const scrollAmount = forwards ? 100 : -100

      listElRef.value?.scroll({
        top: 0,
        left: scrollAmount + hScrollRef.value,
        behavior: 'smooth',
      })
    }

    // Update max horizontal scroll amount
    // mobile only
    const onViewport = (x: number) => {
      if (x > 1024 || !listElRef.value) {
        return
      }

      maxHScrollRef.value =
        listElRef.value.scrollWidth - listElRef.value.clientWidth
    }

    onMounted(() => {
      if (!listElRef.value) {
        return
      }

      maxHScrollRef.value =
        listElRef.value.scrollWidth - listElRef.value.clientWidth

      onScroll(window.scrollX, window.scrollY)
    })

    return {
      rootElRef,
      listElRef,
      isStickyRef,
      hScrollRef,
      maxHScrollRef,
      onScroll,
      onHorizontalScroll,
      onArrowClick,
      onViewport,
    }
  },
})
